import Styled from 'styled-components';

const LoadingWrapper = Styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
`;

const PageWrapper = Styled.div`
    position: relative;
    min-height: 100vh;
`;

const ContentBodyWrapper = Styled.div`
    position: relative;
`;

const ModalPopup = Styled.div`
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.64);

    &.show {
        display: block;
    }

    &.hide {
        display: none;
    }
`;    


export { LoadingWrapper, PageWrapper, ContentBodyWrapper, ModalPopup };
