import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { LoadingWrapper } from 'styles/style';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';
import commonActions from 'redux/common/actions';
import { PRELOAD_IMAGES } from 'config/settings';

const LandingPage = lazy(() => import('pages/LandingPage'));

// Initialize google analytics and track every page
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const NotFound = () => {
    return <Redirect to="/" />;
};

const Routes = (): JSX.Element => {
    const { pathname } = useLocation();

    const dispatch = useDispatch();
    const setTotalAssetsCount = (total: number) => dispatch(commonActions.setTotalAssetsCount(total));
    const addLoadedAssetsCount = () => dispatch(commonActions.addLoadedAssetsCount());

    // Preload images
    useEffect(() => {
        setTotalAssetsCount(PRELOAD_IMAGES.length);

        PRELOAD_IMAGES.forEach((preloadImage) => {
            const image = new Image();
            image.src = preloadImage;
            image.onload = function () {
                addLoadedAssetsCount();
            };
            image.onerror = function () {
                addLoadedAssetsCount();
            };
        });
    }, []);

    // Scroll to top when change path
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Suspense fallback={<LoadingWrapper>{/* <LoadingBar /> */}</LoadingWrapper>}>
            <Switch>
                <Route exact path="/" component={LandingPage} />
                
                {/* Route Not Found */}
                <Route exact path="*" component={NotFound} />
            </Switch>
        </Suspense>
    );
};

export default Routes;
