import CustomCursorClick from 'assets/img/CustomCursor-Click.png';
import NavBg from 'assets/img/nav_background.png';
import Logo from 'assets/img/tomodachi_logo.png';
import SoundOn from 'assets/img/sound_on.svg';
import SoundOff from 'assets/img/sound_off.svg';
import Menu from 'assets/img/menu_icon.png';
import CloseButton from 'assets/img/button_close.png';
import iconInsta from 'assets/img/icon_instagram.svg';
import iconDiscord from 'assets/img/icon_discord.svg';
import iconTwitter from 'assets/img/icon_twitter.svg';
import iconOpensea from 'assets/img/icon_opensea.svg';

import AboutWelcome from 'assets/img/about_welcome.png';
import AboutCloud from 'assets/img/about_cloud.png';

import floppy_id from 'assets/img/floppy_id.png';
import floppyloop from 'assets/gif/floppy_loop.gif';
import closeButton from 'assets/img/close_button.png';
import floppy from 'assets/img/floppy_modal.png';

import konbini_id from 'assets/img/konbini_id.png';
import closeButtonKonbini from 'assets/img/konbini_close.svg';
import konbini from 'assets/img/konbini_modal.svg';

import MetalheadsImage from 'assets/img/metalheads_image.png';

import ResidentBg from 'assets/img/resident_bg.png';

import RoadmapImage from 'assets/img/roadmap_image.png';
import RoadmapImageMobile from 'assets/img/roadmap_image_mobile.png';

import TeamImage from 'assets/img/team_image.png';
import TeamImageMobile from 'assets/img/team_image_mobile.png';

import TomoTokenImage from 'assets/img/tomo_token.png';
import TomoTokenImageMobile from 'assets/img/tomo_token_mobile.png';

import TomoTvImage from 'assets/img/tomo_tv.png';
import TomoTvImageMobile from 'assets/img/tomo_tv_mobile.png';

const PRELOAD_IMAGES: string[] = [
    // Import image assets here
    CustomCursorClick,
    NavBg,
    Logo,
    SoundOn,
    SoundOff,
    Menu,
    CloseButton,
    iconInsta,
    iconDiscord,
    iconTwitter,
    iconOpensea,

    AboutWelcome,
    AboutCloud,

    floppy_id,
    floppyloop,
    closeButton,
    floppy,

    konbini_id,
    closeButtonKonbini,
    konbini,

    MetalheadsImage,

    ResidentBg,

    RoadmapImage,
    RoadmapImageMobile,

    TeamImage,
    TeamImageMobile,

    TomoTokenImage,
    TomoTokenImageMobile,

    TomoTvImage,
    TomoTvImageMobile,
];

export { PRELOAD_IMAGES };
