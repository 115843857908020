import Styled from 'styled-components';
import loadingBg from 'assets/gif/background_loading.gif'

const LoadingOverlayContainer = Styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(${loadingBg});
    background-size: cover;
    z-index: 9999;
    overflow: hidden;

    svg {
        height: 120px;
    }

    @media screen and (min-width:768px) {
        svg {
            height: 200px;
        }
    }
`;


export { LoadingOverlayContainer };
